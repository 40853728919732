<app-loader [enableCircle]="true" [isActive]="this.isLoading$ | async" [width]="20"></app-loader>
<mat-dialog-content>
  <form (submit)="$event.preventDefault()" [formGroup]="this.insertGrp" #form="ngForm">
    <div tg-form-field>
      <div tg-form-field-label>Name</div>
      <div tg-container>
        <input type="text" tg-input formControlName="name" />
        <div class="tg-input-suffix flex flex-row items-center justify-content- mx-1">
          <mat-divider style="width: 1px; height: 18px" class="mr-1" vertical></mat-divider>
          <button mat-icon-button class="mat-button-icon-sm" (click)="resetTaskName()">
            <mat-icon inline>restore</mat-icon>
          </button>
        </div>
      </div>
      <small class="text-warn mt-1 tg-hint" *ngIf="this.insertGrp.controls.name.errors?.minlength">
        {{ 'errors.minlength' | translate: { field: 'Name', length: 3 } }}
      </small>
    </div>
    <ng-container *ngIf="this.daySelector$ | async as daySelector">
      <div tg-form-field>
        <div tg-form-field-label>{{ 'feed.chain-event' | translate }}</div>
        <div tg-container>
          <mat-select
            class="tg-select"
            [compareWith]="selectedDayCompare"
            placeholder="Select Day"
            formControlName="selectedDay"
          >
            <mat-option *ngFor="let day of daySelector; trackBy: day?.toString()" [value]="day">
              {{ day.format('LL') }}
            </mat-option>
          </mat-select>
        </div>
      </div>
    </ng-container>
    <div class="tg-form-field flex flex-col">
      <div class="flex flex-row items-center justify-between">
        <app-time-input
          [style.width.px]="74"
          tg-container
          [inputPlaceholder]="'timer.time.start' | translate"
          [inputClass]="'tg-input tg-input-sm tg-input-center'"
          formControlName="start"
          [displayFormat]="false"
          #tStartFormField
          [maxlength]="5"
          elementRef
          #startInpRef="nativeElement"
          (focus)="this.selectInput($event, startInpRef)"
          (keydown.enter)="$event.preventDefault()"
          (click)="$event.stopPropagation(); this.selectInput($event, startInpRef)"
        ></app-time-input>
        <span class="tg-seperator mx-2">
          <mat-icon>arrow_forward</mat-icon>
        </span>
        <app-time-input
          [style.width.px]="74"
          tg-container
          [inputPlaceholder]="'timer.time.end' | translate"
          [inputClass]="'tg-input tg-input-sm tg-input-center'"
          formControlName="end"
          [displayFormat]="false"
          #tEndFormField
          [maxlength]="5"
          elementRef
          #endInpRef="nativeElement"
          (focus)="this.selectInput($event, endInpRef)"
          (keydown.enter)="$event.preventDefault()"
          (click)="$event.stopPropagation(); this.selectInput($event, endInpRef)"
        >
        </app-time-input>
      </div>
      <small class="text-warn mt-1" *ngIf="this.insertGrp.errors?.range">
        {{ 'errors.record.time-invalid-range' | translate }}
      </small>
    </div>
    <div class="flex flex-row items-center">
      <mat-checkbox formControlName="billable" color="primary">{{ 'Billable' | translate }}</mat-checkbox>
      <div class="flex-grow"></div>
      <button
        (click)="onSubmit()"
        mat-raised-button
        color="primary"
        [disabled]="this.insertGrp?.invalid || (this.isLoading$ | async)"
      >
        {{ 'feed.entry.save' | translate }}
      </button>
    </div>
  </form>
</mat-dialog-content>
