import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TgFormsModule } from '@app/components/tg-forms/tg-forms.module';
import { MaterialModule } from '@app/material.module';
import { LoaderModule } from '@app/shared/loader/loader.module';
import { TimeInputModule } from '@app/shared/time-input/time-input.module';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarInsertTimeDialogComponent } from './calendar-insert-time-dialog.component';

@NgModule({
  declarations: [CalendarInsertTimeDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    LoaderModule,
    FormsModule,

    TgFormsModule,
    ReactiveFormsModule,
    TimeInputModule,
    TranslateModule,
  ],
  exports: [CalendarInsertTimeDialogComponent],
})
export class CalendarInsertTimeDialogModule {}
