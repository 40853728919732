import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'parseFeedDescription',
  pure: false,
})
export class ParseFeedDescriptionPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(value: string): string {
    let bvalue =
      value?.replace('________________________________________________________________________________', '').concat() ||
      '';
    Object.entries(
      bvalue
        .split(' ')
        .map((x) => {
          const a = x.split('i18n:', 2)[1];
          return a;
        })
        .filter((x) => !!x)
        .reduce((l, r) => {
          return { ...l, [`i18n:${r}`]: this.translate.instant('feed.custom-activity-type.' + r) };
        }, {})
    ).forEach(([key, translatedValue]: [string, string]) => {
      bvalue = bvalue.replace(key, translatedValue);
    });
    return bvalue;
  }
}
