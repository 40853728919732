import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseCustomScope',
})
export class ParseCustomScopePipe implements PipeTransform {
  transform(value: string): string {
    return value?.slice(value.indexOf('#') + 1);
  }
}
