import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { FeedHiddenItemsDialogComponent } from './feed-hidden-items-dialog.component';

@NgModule({
  declarations: [FeedHiddenItemsDialogComponent],
  imports: [CommonModule, MaterialModule, TranslateModule],
  exports: [FeedHiddenItemsDialogComponent],
})
export class FeedHiddenItemsDialogModule {}
